import { FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import OptionalServicesModal from '../../modal/OptionalServicesModal';
import { OptionalServices } from '../../../models/app';
import CloseIcon from '@mui/icons-material/Close';

const CartItem = ({ updateCart, product, removeFromCart, calculateTotal, hasAdditionalTax, user }:any) => {

  const [qty,setQty] = useState(product?.quantity);

  useEffect(() => {
    setQty(product?.quantity);
  }, [product]);
  

  const [options,setOptions] = useState<OptionalServices[]>(product.services??[]);
  const [open,setOpen] = useState(false);


  const handleChangeQty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = parseInt(event.target.value);
    if (!isNaN(newQty) && newQty > 0) {
      setQty(newQty);
      product.quantity = newQty;
      updateCart(product);
    }
  };
  

  const calculateServicesTotal = (services: OptionalServices[], quantity: number) => {
    return services.reduce((acc, curr) => {
      const multiplyService = curr.CalcType === "Variable"? quantity : 1;
      return acc + (multiplyService * (curr.Selected ? curr.ServicePriceWithTax : 0))
    }, 0);
  };

  return(
  <>
    <tr>
      <td colSpan={4} className="prod-column">
        <div className="column-box">
        <div 
          className="remove-btn" 
          onClick={() => removeFromCart(product?.id)} 
          style={{
            position: 'absolute',
            left: '0px',
            top: '50%',
            transform: 'translateY(-30%)',
            width: '35px',
            height: '35px',
            lineHeight: '30px',
            fontSize: '10px',
            color: '#141417',
            fontWeight: '900',
            border: '1px solid #e5e5e5',
            textAlign: 'center',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'all 500ms ease',
            // '&:hover': {
            //   color: '#E7131A',
            // },
          }}
        >
          <CloseIcon />
        </div>

          <div className="prod-thumb" style={{width: "110px", height: "110px"}}>
            <img src={product?.image?? require("../../../assets/images/Default-removebg-preview.png")} alt={product?.name} width={"110px"} height={"110px"} style={{maxWidth: "110px", maxHeight: "110px"}} />
          </div>
          <div className="prod-title" style={{maxWidth: "450px", paddingRight: "15px"}}>
            {product?.name} <br /> {product?.description}
          </div>
        </div>
      </td>
      <td className="price">${product?.price.toFixed(2)}</td>
      {product.additionalTax1 && hasAdditionalTax &&
      <td className="price">{`$${parseFloat(product.additionalTax1).toFixed(2)}`}</td>
      }
      <td className="qty">
      <FormControl fullWidth sx={{ borderRadius: "5px", color: "black", marginBottom: "5px", width: "100%" }}>
        <TextField
          id="quantity-select"
          label="Qty"
          type="number"
          value={qty}
          onChange={handleChangeQty}
          InputProps={{ sx: { borderRadius: "5px", color: "black", maxWidth: 80 } }}
          InputLabelProps={{ shrink: true }}
          size='small'
        />
      </FormControl>

      </td>
      <td className="sub-total">${(((product?.price + (parseFloat(product.additionalTax1)?? 0)) * (qty?qty:product?.quantity)) ).toFixed(2)}</td>
      {((user !== "") && ((user === "guest") || (user.showAdditionnalServices === true))) && <td><div className="theme-btn theme-btn-eight" style={{fontSize: "14px", cursor: "pointer", borderRadius: "10px"}} onClick={()=>setOpen(!open)}>{`selected Services: $${(calculateServicesTotal(product.services, qty)).toFixed(2)}`}</div></td>}
    </tr>
    {options.length!==0 && <OptionalServicesModal product={product} updateCart={updateCart} calculateTotal={calculateTotal} open={open} setOpen={setOpen} fields={options} setOptions={setOptions} isCart={true}/>}
  </>
);
  }
export default CartItem;
