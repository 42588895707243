/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Logo from "../UI/logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
import
{
  Brand,
  Location,
  CartItem,
  OptionalServices,
  LoggedinUser,
} from "../../models/app";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useIsMobile from "../../hooks/useIsMobile";
import
{
  InputLabel,
  Select,
  styled,
  FormControl,
  MenuItem,
  OutlinedInput,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import
{
  updateSelectedLocation,
  updateUserSystemId,
} from "../../store/ducks/app";
import AccountCircleIcon from "@mui/icons-material/PermIdentity";

interface HeaderProps
{
  handleOpenMobileMenu: () => void;
  brands: any;
  locationsLoading: boolean;
}

export interface HeaderQueryParams
{
  selectedBrand: string;
}

const StyledFormControl = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent",
    "& fieldset": {
      borderColor: "#2B313E", // default
    },
    "&:hover fieldset": {
      borderColor: "#2B313E", // hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2B313E", // focused
    },
  },
  "& .MuiInputLabel-root": {
    color: "#2B313E", // default
    "&.Mui-focused": {
      color: "#2B313E", // focused
    },
  },
});

const Header: React.FC<HeaderProps> = ({ handleOpenMobileMenu, brands , locationsLoading }) =>
{
  
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationsStore: Location[] = useSelector(
    (state: RootState) => state?.store?.locations
  );
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  const userSystemIdStore = useSelector(
    (state: RootState) => state?.store?.userSystemId
  );

  const cart: CartItem[] = useSelector((state: RootState) => state.store?.cart);
  const discount = useSelector((state: any) => state?.store?.discountApplied);
  const discountPercentage = useSelector(
    (state: any) => state?.store?.discountPercentage
  );
  const { web } = useIsMobile();
  const user: LoggedinUser | "" | "guest" = JSON.parse(
    localStorage.getItem("user") ?? `""`
  );
  const isLoggedIn = user !== null && user !== "" && user && user !== "guest";
  const [totalPrice, setTotalPrice] = useState(0);

  const calculateTotal = (discountApplied?: boolean) =>
  {
    // console.log("discountApplied: ", discountApplied)

    var newPrice = cart.reduce((acc: number, curr: CartItem) =>
    {
      const serviceTotal = calculateServicesTotal(curr.services ?? []);
      return acc + curr.price * curr.quantity + serviceTotal;
    }, 0);

    if (discountApplied)
    {
      const discountAmount = (discountPercentage * newPrice) / 100;
      const priceWithDiscount = newPrice - discountAmount;
      newPrice = priceWithDiscount;
    }
    // console.log("discountApplied: ", discountApplied, "discount: ", discountAmount, "newPrice: ", newPrice)

    setTotalPrice(newPrice);
  };

  const calculateServicesTotal = (services: OptionalServices[]) =>
  {
    return services.reduce(
      (acc: number, curr: OptionalServices) =>
        acc + (curr.Selected ? curr.ServicePriceWithTax : 0),
      0
    );
  };

  // const handleBrandSelection = (selectedBrand: string) =>
  // {
  //   // const params: HeaderQueryParams = {
  //   //   selectedBrand: selectedBrand,
  //   // };
  //   // let queryString = new URLSearchParams(params as any).toString();
  //   // navigate(`/models?${queryString}`);
  //   console.log(selectedBrand)
  //   navigate(`/Tire-Brands/${selectedBrand.replace(' ', '-')}`);
  // };
  const handleLocationChange = (event: any) =>
  {
    setLocationModalOpen(false)

    const chosenLocation = locationsStore.find(
      (location: Location) => location.code === event.target.value
    );
    // console.log(event.target.value, chosenLocation);
    if (chosenLocation)
    {
      dispatch(updateSelectedLocation(chosenLocation));
      const flag = locationsStore.find(
        (location: Location) => location.guestCutomerId === userSystemIdStore
      );
      if (flag)
      {
        dispatch(updateUserSystemId(chosenLocation.guestCutomerId));
      }
    }
  };

  useEffect(() =>
  {
    if (discount)
    {
      calculateTotal(discount);
    } else
    {
      calculateTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discount]);

  const [showTireBrands, setShowTireBrands] = useState(false);
  // console.log(locationsStore);
  return (
    <>
      <Grid
        container
        style={ {
          backgroundColor: "#fff",
          position: "fixed",
          boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.2)",
          zIndex: 10,
        } }
      >
        <header className="main-header ex_shop_header header-style-one">
          <div
            className="auto-container"
            style={ {
              display: "flex",
              alignItems: "center",
              height: "auto",
              minHeight: "80px",
              padding: "0px",
              margin: "10px",
              marginTop: "0px",
              // maxWidth: "95vw",
            } }
          >
            {/* Logo on the left */ }
            <div
              className="logo-box"
              style={ {
                display: "flex",
                alignItems: "center",
                width: "220px",
                height: "80px",
                marginRight: "5px",
                marginLeft: "15px",
              } }
            >
              <Logo width={ "220px" } />
            </div>
            <div
              style={ {
                display: "flex",
                flexDirection: "column",
                width: "100%",
              } }
            >
              {/* <!-- header-lower --> */ }
                <div
                  className="header-lower"
                  style={ {
                    height: "auto",
                    minHeight: "40px",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid black",
                    zoom: 0.9,
                  } }
                >
                  <div
                    className="auto-container"
                    style={ {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      height: "100%",
                    } }
                  >
                    <div
                      className="outer-box"
                      style={ {
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        backgroundColor: "transparent",
                      } }
                    >
                      {/* Location Selector */ }
                      <Grid item xs={ 4 } md={ 3 } lg={ 4 }>
                        <div
                          // className="location-selector"
                          // style={{ flexBasis: "25%" }}
                          style={ { backgroundColor: "transparent" } }
                        >
                          <StyledFormControl
                            
                            fullWidth
                            margin="dense"
                            sx={ {
                              zIndex: 99999,
                              width: "100%",
                              ".MuiInputBase-root": {
                                height: 40,
                                fontSize: "0.875rem",
                              },
                              ".MuiOutlinedInput-input": {
                                padding: "10px 14px",
                              },
                              ".MuiInputLabel-root": { fontSize: "0.875rem" },
                            } }
                          >
 
                            <InputLabel id="demo-multiple-name-label" style={{display:'flex' , alignItems:'center', justifyContent:'center'}}>
                              {locationsLoading && <CircularProgress size={"18px"} color="error" />}
                              Locations

                            </InputLabel>
                            <Select
                              id="demo-multiple-name"
                              value={ !selectedLocation  ?"" : selectedLocation?.code ?? locationsStore[0]?.code }
                              open={locationModalOpen}
                              onChange={ handleLocationChange }
                              onClose={ () => setLocationModalOpen(false)}
                              onOpen={ () => setLocationModalOpen(true)}
                              input={ <OutlinedInput label="Location" /> }
                              fullWidth
                              sx={ {
                                zIndex: 9999,
                                // borderRadius: "10px",
                                ".MuiSvgIcon-root": { fontSize: "1rem" }, // Adjust the dropdown icon size if necessary
                              } }
                              required
                              renderValue={ (selectedCode) =>
                              {
                                const selected = locationsStore.find(
                                  (location: any) =>
                                    location.code === selectedCode
                                );
                                return (
                                  <>
                                    <LocationOnIcon
                                      sx={ {
                                        color: "red",
                                        marginRight: "5px",
                                      } }
                                    />
                                    <Tooltip
                                      title={
                                        selectedLocation?.displayName +
                                        " " +
                                        selectedLocation?.address
                                      }
                                    // arrow
                                    >
                                      <span style={{color:'black'}}>{ `My Store: ${selected?.displayName + " " + selected?.address
                                        }` }</span>
                                    </Tooltip>
                                  </>
                                );
                              } }
                            >
                              {locationModalOpen && locationsStore &&
                                locationsStore.map((location: Location) => (
                                  <MenuItem
                                    key={ location.code }
                                    value={ location.code }
                                    sx={ {
                                      color: "black",
                                      backgroundColor: "transparent",
                                      fontSize: "0.875rem",
                                    } }
                                  >
                                    { location?.displayName + " " + location?.address }
                                  </MenuItem>
                                )) }
                            </Select>
                          </StyledFormControl>
                        </div>
                      </Grid>
                      <Grid item xs={ 4 } md={ 4 } lg={ 3 }>
                        <div
                          className="menu-area clearfix"
                          style={ {
                            display: "flex",
                            padding: "0px",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          } }
                        >
                          <nav
                            className="main-menu clearfix navbar-expand-md navbar-light"
                            style={ { width: "100%" } }
                          >
                            <div
                              className="collapse navbar-collapse show clearfix"
                              id="navbarSupportedContent"
                              style={ {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",

                                alignItems: "center",
                                width: "100%",
                              } }
                            >
                              <ul
                                className="navigation clearfix home-menu"
                                style={ {
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-around",
                                  marginLeft: "2rem",
                                } }
                              >
                                <li className="dropdown">
                                  <a href="/" className="first-menu">
                                    Home
                                  </a>
                                  <div className="tireBrandList2">
                                    <div>
                                      <a href="/coupons">Coupons </a>
                                    </div>
                                    <div>
                                      <a href="/contact">Contact</a>
                                    </div>
                                    <div>
                                      <a href="/aboutus">About</a>
                                    </div>

                                    <div
                                      className="tireBrandList3"
                                      onMouseEnter={ () =>
                                        setShowTireBrands(true)
                                      }
                                      onMouseLeave={ () =>
                                        setShowTireBrands(false)
                                      }
                                    >
                                      <div style={{display:'none'}}>
                                      <a href= {`/Tire-Brands/ACCELERA`}>ACCELERA</a>
                                      <a href= {`/Tire-Brands/ACHILLES`}>ACHILLES</a>
                                      <a href= {`/Tire-Brands/AMERICUS`}>AMERICUS</a>
                                      <a href= {`/Tire-Brands/ATTURO`}>ATTURO</a>
                                      <a href= {`/Tire-Brands/BARUM`}>BARUM</a>
                                      <a href= {`/Tire-Brands/BF-GOODRICH`}>BFGOODRICH</a>
                                      <a href= {`/Tire-Brands/BRIDGESTONE`}>BRIDGESTONE</a>
                                      <a href= {`/Tire-Brands/CONTINENTAL`}>CONTINENTAL</a>
                                      <a href= {`/Tire-Brands/COOPER`}>COOPER</a>
                                      </div>
                                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                                      <a href="https://catalog.tirezoneusa.com/" target="_blank" style={{color:"#2B313E !important" , fontWeight:300}} rel="noreferrer">Tire Catalog</a>
                                      {/* { showTireBrands && (
                                        <div
                                          className="megamenu"
                                          style={ {
                                            position: "absolute",
                                            width: "100%",
                                            maxWidth: "760px",
                                            minWidth: "760px",
                                          } }
                                        >
                                          <div
                                            className="megamenu-container-inner"
                                            style={ {
                                              width: "100%",
                                              height: "100%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            } }
                                          >
                                            <div className="megamenu-wrapper">
                                              <div className="megamenu-content">
                                                <h5>TIRE BRANDS</h5>
                                                <br />
                                                <div
                                                  style={ { cursor: "pointer" } }
                                                >
                                                  { brands?.map(
                                                    (
                                                      brand: Brand,
                                                      index: number
                                                    ) => (
                                                      // <h6
                                                      <a href= {`/Tire-Brands/${brand.Code}`}
                                                        className="header_h6"
                                                        // onClick={ () =>
                                                        //   handleBrandSelection(
                                                        //     brand.Code
                                                        //   )
                                                        // }
                                                      >
                                                        { brand.Code }
                                                        </a>
                                                      // </h6>
                                                    )
                                                  ) }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) } */}
                                    </div>
                                  </div>
                                </li>
                                <li className="dropdown">
                                  {
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <h6 style={{cursor:'pointer'}}>Locations</h6>
                                  }
                                  <div className="tireBrandList2">
                                    { locationsStore?.map(
                                      (location: Location, index: number) =>
                                      {
                                        return (
                                          <div
                                            key={ index }
                                          >
                                            <a href={"/location/"+location?.name?.replace(" ","-")}>{ location?.displayName }</a>
                                          </div>
                                        );
                                      }
                                    ) }
                                  </div>
                                </li>

                              </ul>
                            </div>
                          </nav>
                        </div>
                      </Grid>
                      {/* Navbar Right Info */ }
                      <Grid item xs={ 4 } md={ 5 } lg={ 5 }>
                        <div
                          className="navbar-right-info"
                          style={ {
                            // flexBasis: "30%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          } }
                        >
                          <ul
                            className="navigation right-info"
                            style={ {
                              display: "flex",
                              alignItems: "center",
                              margin: 0,
                              padding: 0,
                              color: "black",
                            } }
                          >
                            <li className="dropdown">
                              { isLoggedIn ? (
                                <a
                                  href="/accountPage"
                                  className="dropdown-toggle"
                                  style={ {
                                    color: "black",
                                    textDecoration: "none",
                                    borderRight: '2px solid black',
                                    paddingRight: '0.5rem'
                                  } }
                                >
                                  <AccountCircleIcon
                                    sx={ {
                                      color: "black",
                                      verticalAlign: "middle",
                                    } }
                                  />{ " " }
                                  { (window.innerWidth < 1100
                                    ? user?.name?.split(" ", 1)
                                    : user?.name) ?? "Account" }
                                </a>
                              ) : (
                                <h6 style={{fontWeight:500 , cursor:'pointer'}}>Join/Sign In</h6>
                              ) }
                              <div className="tireBrandList2">
                                { isLoggedIn ? (
                                  <>
                                    <div>
                                      <a href="/myInvoices">Invoices</a>
                                      <a href="/mySalesOrders">Sales Orders</a>
                                      <a
                                        href="/"
                                        onClick={ (e) =>
                                        {
                                          e.preventDefault();
                                          localStorage.clear();
                                          window.location.href = "/";
                                        } }
                                      >
                                        Logout
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    {/* {" "} */ }
                                    <a href="/signup">Join</a>
                                    <a href="/login">Sign In</a>
                                  </div>
                                ) }
                              </div>
                            </li>
                            <li style={ { fontSize: "0.875rem" } }>
                              <a href="/cart" aria-label="shopping-cart">
                                <div className="shopping-cart">
                                  Cart
                                  <i
                                    className="icon-shopping-cart"
                                    style={ { color: "black", fontSize: "1rem" } }
                                  ></i>
                                  <span
                                    className="count"
                                    style={ {
                                      color: "white",
                                      fontSize: "0.75rem",
                                      marginLeft: "5px",
                                    } }
                                  >
                                    { cart?.length ?? 0 }
                                  </span>
                                </div>
                                <div
                                  className="shopping-cart-info"
                                  style={ { marginRight: 5 } }
                                >
                                  <h5
                                    style={ {
                                      color: "black",
                                      fontSize: "0.75rem",
                                      whiteSpace: "nowrap",
                                    } }
                                  >
                                    { cart?.length ?? 0 } items
                                  </h5>
                                  <h6
                                    style={ {
                                      color: "black",
                                      fontSize: "0.875rem",
                                      whiteSpace: "nowrap",
                                    } }
                                  >{ `$ ${totalPrice.toFixed(2) ?? 0}` }</h6>
                                </div>
                              </a>
                            </li>

                            {/* Menu Icon for Mobile */ }
                            { !web && (
                              <Menu
                                sx={ {
                                  ml: "1rem",
                                  color: "black",
                                  fontSize: "1.5rem",
                                } }
                                onClick={ handleOpenMobileMenu }
                              />
                            ) }
                          </ul>
                        </div>
                      </Grid>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </header>
      </Grid>
      <header style={ { minHeight: "80px" } }></header>
    </>
  );
};

export default Header;
