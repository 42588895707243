import { useState } from "react";
import { GetLogin } from "../services/GetLogin";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, editCartItem, updateSelectedLocation, updateUser, updateUserSystemId } from "../store/ducks/app";
import { RootState } from "../store/ducks";
import { CartItem, Location, LoggedinUser, Product, SearchBody, SearchResultsApiResponse } from "../models/app";
import { useGoogleLogin } from '@react-oauth/google';
import { getProducts } from "../services/GetProducts";
import { GetLoginGoogle } from "../services/GetLoginGoogle";

export const useLogin = () => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userInfo, setData] = useState();
  const [products, setProducts] = useState<Product[]>([]);
  const locationsStore = useSelector((state: RootState) => state?.store?.locations);
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    const storedCart = localStorage.getItem('cart');
      return storedCart ? JSON.parse(storedCart) : [];
    });

  const dispatch = useDispatch();

  const login = async (body: any, isCheckout: boolean) => {
    try {
      setLoading(true);

      const apiResponse = await GetLogin( body);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        const user = apiResponse?.Data[0]
        const {password, ...newUser} = user

        const newUserTemp: LoggedinUser = {...newUser};
        // if(newUserTemp.customerPriceGroup === "WHOLESALE"){
          if(cartItems.length > 0){
            await handleWholeSaleCart(newUserTemp.id, newUserTemp);
          }
        // }

        dispatch(updateUserSystemId(newUser.id));
        dispatch(updateUser(newUser));
        const matchingLocation = locationsStore.find((location: Location) => location.code === newUser.locationCode);
        if (matchingLocation) {
            dispatch(updateSelectedLocation(matchingLocation));
        }
        else{
          dispatch(updateSelectedLocation(locationsStore[0]));
        }
        if(!isCheckout){
          window.location.href = "/accountPage";
        }
        else{
          // console.log("isCheckout", isCheckout);
          return newUser;
        }

    }
} catch (error: any) {
    setError("Error logging in");
} finally {
        setLoading(false);
    }
  };

  const GoogleLoginService = async (body: any, isCheckout: boolean) => {
    try {
      setLoading(true);

      const apiResponse = await GetLoginGoogle(body);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        const user = apiResponse?.Data[0]
        const {password, ...newUser} = user
        dispatch(updateUserSystemId(newUser.id));
        dispatch(updateUser(newUser));
        const matchingLocation = locationsStore.find((location: Location) => location.code === newUser.locationCode);
        if (matchingLocation) {
            dispatch(updateSelectedLocation(matchingLocation));
        }
        else{
          dispatch(updateSelectedLocation(locationsStore[0]));
        }
        if(!isCheckout){
          window.location.href = "/accountPage";
        }
        else{
          // console.log("isCheckout", isCheckout);
          return newUser;
        }

    }
} catch (error: any) {
    setError("Error logging in");
} finally {
        setLoading(false);
    }
  };

  const handleWholeSaleCart = async (userId: string, newUserTemp:LoggedinUser) =>{
    const idsArray = cartItems.map((item) => item.id);
    const body = formatSearchBody(idsArray);
    try {
      const apiResponse: SearchResultsApiResponse = await getProducts(body, userId, false);
  
      if (!apiResponse?.Data?.data || apiResponse?.Data?.data.length === 0) {
        dispatch(clearCart());
        return;
      }  
      const result = apiResponse.Data.data;
  
      result.forEach((updatedProduct) => {
        const existingItem = cartItems.find((item) => item.id === updatedProduct.SystemId);
        if (existingItem) {

          const newData: CartItem = { 
            locationCode: existingItem.locationCode,
            id: existingItem.id,
            no: updatedProduct.No??existingItem.no,
            name: existingItem.name,
            image: updatedProduct.Image_Source??existingItem.image,
            description: updatedProduct.Description??existingItem.description,
            price: updatedProduct.UnitPrice??existingItem.price,
            salesTax: updatedProduct.salesTax??existingItem.salesTax,
            additionalTax1: updatedProduct.additionalTax1??existingItem.additionalTax1,
            additionalTax2: updatedProduct.additionalTax2??existingItem.additionalTax2,
            additionalTax1Caption: updatedProduct.additionalTax1Caption??existingItem.additionalTax1Caption,
            additionalTax2Caption: updatedProduct.additionalTax2Caption??existingItem.additionalTax2Caption,
            quantity: existingItem.quantity,
            services: newUserTemp.showAdditionnalServices===true?existingItem.services:[],
          }

          dispatch(editCartItem({ id: existingItem.id, data: newData, }));
        }
      });
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  }

  const formatSearchBody = (input: string[]): SearchBody[] => {
    return input.map((id) => ({
      Field: "systemId",
      Value: id,
    }));
};

  return { login, res, loading, error, userInfo , GoogleLoginService };
};
