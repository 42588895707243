import React, {  useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Season } from "../../constants/enums";
import { AppState, CartItem, Location, OptionalServices, Product, Review, Stock } from "../../models/app";
import TireDetailReview from "../../components/TireDetailReview";
import PageTitle from "../../components/PageTitle";
import { Box, Button, Dialog, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { addToCart, updateSelectedLocation, updateUserSystemId } from "../../store/ducks/app";
import { useGetAdditionalServices } from "../../hooks/useGetAdditionalServices";
import Loader from "../../components/UI/loader/Loader";
import OptionalServicesModal from "../../components/modal/OptionalServicesModal";
import { useCart } from "../../hooks/useCart";
import { RootState } from "../../store/ducks";
import Alert from "../../components/UI/alert/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import { parse } from "path";
import { Helmet } from "react-helmet";
import StarIcon from '@mui/icons-material/Star';
import ReactHtmlParser from 'react-html-parser';
interface TireDetailsPageProps{
    item: Product;
    handleAddToCart?: (item: any)=>void;
    getLocationsQuantities: (product: Product, location?:Location)=>Stock[];
}

const TireDetailsPage: React.FC<TireDetailsPageProps> = ({item, getLocationsQuantities}:TireDetailsPageProps) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const locationsStore = useSelector((state: RootState) => state?.store?.locations);
    const selectedLocationStore = useSelector((state: RootState) => state?.store?.selectedLocation);
    const userSystemIdStore = useSelector((state: RootState) => state?.store?.userSystemId);

    const [locations, setLocations] = useState<Location[]>(locationsStore);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(selectedLocationStore);
    const [userSystemId, setUserSystemId] = useState(userSystemIdStore);
    const [descriptionTab, setDescriptionTab] = useState("tab");
    const [specsTab, setSpecsTab] = useState("tab");
    const [reviewsTab, setReviewsTab] = useState("tab");
    const [activeTab, setActiveTab] = useState(false);
    const [activeTabClass, setActiveTabClass] = useState("tab-btn active-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 mr_35 tran_5");
    const [nonActiveTabClass, setNonActiveTabClass] = useState("tab-btn p_relative d_iblock fs_18 font_family_inter lh_20 float_left fw_medium z_1 mr_35 tran_5");
    const [quantity, setQuantity] = useState(4);
    const [options,setOptions] = useState<OptionalServices[]>([]);
    const [open,setOpen] = useState(false);
    const [stock, setStock] = useState<Stock[]>(getLocationsQuantities(item));
    const initialLocationIndex = stock.findIndex((oneStock: Stock) => oneStock.locationObj.guestCutomerId === selectedLocation?.guestCutomerId).toString();
    const [locationQuantity, setLocationQuantity] = useState<string>(initialLocationIndex);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const totalQuantity = stock.reduce((acc, currentStock) => acc + currentStock.qty, 0);

    
  const [newCartItem, setNewCartItem] = useState<CartItem>(
    { 
        locationCode: selectedLocation?.code?? selectedLocationStore?.code,
        id: item?.SystemId??"",
        no:item?.No??"", 
        name: item?.Model??"Product Name", 
        quantity: quantity??0, 
        image: item?.Image_Source??"../../assets/images/product/blog-1.jpg", 
        description: item?.Description??"", 
        price: item?.UnitPrice??0,  
        salesTax: item?.salesTax,
        additionalTax1: item?.additionalTax1,
        additionalTax2: item?.additionalTax2,
        additionalTax1Caption: item?.additionalTax1Caption,
        additionalTax2Caption: item?.additionalTax2Caption,
        services: []
    });

  const { optionalServices, optionalServicesLoading, optionalServicesError } =
  useGetAdditionalServices(item?.SystemId, `1`, "");

  const { totalPrice, updateCart, cartItems, setCartItems, calculateTotal, removeFromCart } =
  useCart(false);

  const cart = useSelector((state: RootState) => state?.store?.cart);
  const user = useSelector((state: RootState) => state?.store?.user);

  const isUser = (user !== "" && user!=="guest")

  useEffect(()=> {
    buttonState(item, selectedLocationStore);
  }, [selectedLocationStore, cart]);
    
    // const openReviews = () =>{
    //     if(reviewsTab === "tab active-tab"){
    //         setActiveTab(false);
    //         setReviewsTab("tab");
    //     }
    //     else{
    //         setActiveTab(true);
    //         setReviewsTab("tab active-tab");
    //     }
    //     setDescriptionTab("tab");
    //     setSpecsTab("tab");
    // }

    const handleImageClick = () => {
        setImageModalOpen(true);
    };

    const openDescription = () =>{
        if(descriptionTab === "tab active-tab"){
            setActiveTab(false);
            setDescriptionTab("tab");
        }
        else{
            setActiveTab(true);
            setDescriptionTab("tab active-tab");
        }
        setReviewsTab("tab");
        setSpecsTab("tab");
    }

    const openSpecs = () =>{
        if(specsTab === "tab active-tab"){
            setActiveTab(false);
            setSpecsTab("tab");
        }
        else{
            setActiveTab(true);
            setSpecsTab("tab active-tab");
        }
        setReviewsTab("tab");
        setDescriptionTab("tab");
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(event.target.value);
        if (!isNaN(newQuantity) && newQuantity > 0) {
            setQuantity(newQuantity);
        }
        // console.log(newQuantity);
    };

    const handleWareHouseChange = (event: SelectChangeEvent<string>) => {
        // const selectedIndex = parseInt(event.target.value, 10);
        // if (!isNaN(selectedIndex)) {
        //     const selectedStock = stock[selectedIndex];
        //     localStorage.setItem('location', JSON.stringify(selectedStock.locationObj));
        //     dispatch(updateSelectedLocation(selectedStock.locationObj));
        //     dispatch(updateUserSystemId(selectedStock.locationObj.guestCutomerId));
        //     setLocationQuantity(selectedIndex.toString()); // Ensure this is a string to match the expected type
        // }
    };

    const handleAddServices = () =>{
        const cart:CartItem[] = JSON.parse(localStorage.getItem("cart")??"[]");
        if(user === "" || user==="guest" || user.showAdditionnalServices === true){
            setOpen(true);
            setError(false);            
        }
        else{
            // console.log(" else ");
            if((cart.length === 0)||((cart.length > 0)  && (selectedLocationStore) && (selectedLocationStore?.code === cart[0].locationCode))){
                const newItem:CartItem = { locationCode: selectedLocationStore?.code, id: item?.SystemId??"", no:item?.No??"", name: item?.Model??"Product Name", quantity: quantity??0, image: (item?.Image_Source && item?.Image_Source?.length>0)?item?.Image_Source:"../../assets/images/product/blog-1.jpg", description: item?.Description??"", price: item?.UnitPrice??0, services: [], salesTax: item?.salesTax??"", additionalTax1: item?.additionalTax1??"", additionalTax1Caption: item?.additionalTax1Caption??"", additionalTax2: item?.additionalTax2??"", additionalTax2Caption: item?.additionalTax2Caption??"" };
                // console.log(" 1 ", item, newItem);
                dispatch(addToCart(newItem));
                setOpen(false);
                navigate('/cart')
            }
            else{
                setShowConfirmationModal(true);
            }
        }
        


        // if(quantity <= stock[parseInt(locationQuantity, 10)]?.qty){
        // }
        // else{
        //     quantity === 0? setErrorMessage("Please Select Number Of Tires Needed") : setErrorMessage("Not Enough In Stock");
        //     setError(true);
        // }
    }

    const buttonState = (item: Product, location?: Location) : string=> {
        const cart:CartItem[] = JSON.parse(localStorage.getItem("cart")??"[]");
        // if(stock.every((onestock:Stock)=>onestock.qty === 0)){
        //     return "OUT OF STOCK";             
        // }    
        // else 
        if((cart.length === 0)||((cart.length > 0) && (selectedLocationStore) && (selectedLocationStore?.code === cart[0].locationCode))){
            if((cart.length > 0) && (cart.find(item1 => item1.id === item.SystemId))){
                return "ADDED TO CART"; 
            }
        }
        return "ADD TO CART"; 
    };

    const styles = {
        leftTriangle: {
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0px 20px 14px 0px',
          borderColor: 'transparent rgb(28.5, 99, 30) transparent transparent',
          zIndex: 2
        },
        rightTriangle: {
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0px 0 14px 20px',
          borderColor: 'transparent transparent transparent rgb(28.5, 99, 30)',
          zIndex: 2
        }
      };

      const parseHTML = (htmlString: string) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
      };

    return (
        <Grid container>
            <Helmet>
                <meta property="og:title" content={item.Model} />
                <meta property="og:description" content={`Check out the details of ${item.Model} at TireZone`} />
                <meta property="og:image" content={item.Image_Source ?? "../../assets/images/no-image-icon-23485.png"} />
                <meta property="og:url" content="http://tirezone.co" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={item.Model} />
                <meta name="twitter:description" content={`Check out the details of ${item.Model} at TireZone`} />
                <meta name="twitter:image" content={item.Image_Source ?? "../../assets/images/no-image-icon-23485.png"} />
            </Helmet>
            <section className="shop-details p_relative" style={{ width: "100%", borderBottom: "2px solid #e5e5e5", marginBottom: "0px" }}>
                <div className="product-details-content p_relative" style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <Grid container spacing={2} sx={{padding: "15px"}}>
                        {(item.modelOfferText && item.modelOfferText.length>0) && <> 
                            <Grid item xs={12} sx={{height: "45px", backgroundColor: "rgb(38, 132, 40)", color: "rgb(255, 255, 255)", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "0px !important"}}>
                                {/* <StarIcon style={{ color: "white" }} />
                                    <h5 style={{color: "white"}}>{item.modelOfferText ?? ""}</h5>
                                <StarIcon style={{ color: "white" }} /> */}
                                {ReactHtmlParser(item.modelOfferText)} 
                                
                            </Grid>
                            <Grid item xs={12} sx={{height: "25px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", padding: "0px !important"}}>
                                <div style={styles.leftTriangle}></div>
                                <div style={styles.rightTriangle}></div>
                            </Grid>
                        </>}
                        <Grid item xs={12} sm={3} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            { item.brand_image_source? <img src={item.brand_image_source} alt="Model's Logo" style={{ width: "180px", height: "auto", marginBottom: "0px" }} /> : <h3>{item.Model??""}</h3>}
                            <img src={item.Image_Source?? require("../../assets/images/no-image-icon-23485.png")} alt={item.Model} style={{ width: "auto", height: "150px", cursor: "pointer", marginBottom: "10px" }} onClick={handleImageClick} />
                            <Dialog open={imageModalOpen} onClose={() => setImageModalOpen(false)}>
                                <DialogContent sx={{ overflow: "hidden", width: "400px", height: "400px", position: "relative" }}>
                                    <IconButton 
                                        style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }} 
                                        onClick={() => setImageModalOpen(false)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img 
                                            src={item.Image_Source ?? require("../../assets/images/no-image-icon-23485.png")} 
                                            alt={item.Model} 
                                            style={{ maxWidth: "100%", maxHeight: "100%" }} 
                                        />
                                    </div>
                                </DialogContent>
                            </Dialog>

                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                {/* Warranty, Brand, and Price Section */}
                                <Grid item xs={12} sm={4} style={{padding: "0px"}}>
                                    <Typography>{item.Brand ?? "N/A"}</Typography>
                                    <Typography sx={{marginTop: "5px"}}>{item.Model ?? "N/A"}</Typography>
                                    <Typography noWrap sx={{marginTop: "5px"}}>{`${item.TireSize} ${item.loadIndex} ${item.speedRating}`}</Typography>
                                    <Typography sx={{ fontSize: "18px", mb: 2, display: 'flex', alignItems: 'center', color: "black", marginTop: "35px" }}>
                                        <LocationOnIcon sx={{ fontSize: "20px" }} />
                                        {stock[parseInt(locationQuantity, 10)]?.locationObj.name} {" ( "} {stock[parseInt(locationQuantity, 10)]?.qty} {" ) in stock"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} style={{ padding: "0px"}}>
                                <Grid container spacing={1} sx={{ marginBottom: "15px", padding: "0px" }}>
                                    <Grid item xs={6}>
                                    <Grid sx={{ padding: "0px"}}>
                                        <Grid item xs={12} >
                                        <Box display="flex" alignItems="center" width="100%">
                                            <Typography fontWeight="bold" fontSize="24px" color="#E7131A">
                                            {`$${item.UnitPrice.toFixed(2) ?? '-'}`}
                                            </Typography>
                                            <Typography fontWeight="bold" fontSize="18px" color="#E7131A" sx={{ ml: 0.5 }}>
                                            {((item.unitOfMeasureDescription) && (item.unitOfMeasureDescription.length>0))? (item.unitOfMeasureDescription) : `EA` }
                                            </Typography>
                                            {/* WHOLESALE Price Description */}
                                            {isUser &&  item.alternatePrice !=="0" && 
                                            <Typography fontWeight="bold" fontSize="16px" color="#E7131A" sx={{ ml: 0.5 }}>
                                            /qty {item.priceDescription}
                                            </Typography>
                                            }
                                        </Box>

                                        {/* WHOLESALE Alternate Price  */}
                                        {isUser &&  item.alternatePrice !=="0" &&
                                            <Box display="flex" alignItems="center">
                                            <Typography fontWeight="bold" fontSize="24px" color="#E7131A">
                                            {`$${parseFloat(item.alternatePrice??"").toFixed(2) ?? ""}`}
                                            </Typography>
                                            <Typography fontWeight="bold" fontSize="18px" color="#E7131A" sx={{ ml: 0.5 }}>
                                            {((item.unitOfMeasureDescription) && (item.unitOfMeasureDescription.length>0))? (item.unitOfMeasureDescription) : `EA` }
                                            </Typography>
                                            <Typography fontWeight="bold" fontSize="16px" color="#E7131A" sx={{ ml: 0.5 }}>
                                            /qty {item.alternatePriceDescription}
                                            </Typography>
                                        </Box>
                                        }
                                        {/* Tax Display */}
                                        {item.additionalTax1 && item.additionalTax1 !=="0" && 
                                        <Box display="flex" alignItems="center">
                                            <Typography fontWeight="bold" fontSize="16px" color="#E7131A">
                                                {`+$${parseFloat(item.additionalTax1).toFixed(2) ?? ""}`}
                                            </Typography>
                                            <Typography fontWeight="bold" fontSize="12px" color="#E7131A" sx={{ ml: 0.5 }}>
                                            FET
                                            </Typography>
                                        </Box>
                                        }
                                        </Grid>
                                    </Grid>
                                    </Grid>

                                    <Grid item xs={6} sm={6}>
                                        <Grid container sx={{ padding: "0px" }}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">UTQG: {item.Utqg ?? "N/A"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">SideWall: {item.SideWall ?? "N/A"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Warranty: {item.Warranty ?? "N/A"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">MFR Part No: {item.MfrPartNo ?? "N/A"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                
                                                <Typography variant="body2">OE: {(item?.oEDescription && item?.oEDescription?.length>0) ? item.oEDescription : "N/A"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                
                                                <Typography variant="body2">Run Flat: {(item?.RunFlat ) ? "Yes" : "N/A"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                                {/* <Grid item xs={12} sm={7} style={{padding: "0px"}}>
                                    <Grid container spacing={0} sx={{marginBottom: "15px", padding: "0px"}}>
                                        <Grid item xs={7}>
                                            <Grid container sx={{padding: "0px"}}>
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold" fontSize={"20px"} color={"#E7131A"}>{`$${item.UnitPrice.toFixed(2) ?? 100}`} <Typography fontWeight="bold" fontSize={"19px"} color={"#E7131A"}> /ea</Typography></Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                </Grid>
                                                <Grid item xs={12}>
                                                </Grid>
                                                <Grid item xs={12}>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Grid container sx={{padding: "0px"}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">UTQG: {item.Utqg ?? "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">SideWall: {item.SideWall ?? "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                <Typography variant="body2">Warranty: {item.Warranty ?? "N/A"}</Typography>
                                                </Grid>
                                                {<Grid item xs={12}>
                                                <Typography variant="body2">MFR Part No: {item.MfrPartNo ?? "N/A"}</Typography>
                                                </Grid>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                            </Grid>

                            <div className="addto-cart-box p_relative d_block " style={{display: 'flex', flexDirection: "row", justifyContent: "flex-start", width: "100%", marginTop: "5px"}}> 
                                <ul className="clearfix">
                                    <li className="p_relative d_block float_left"  style={{marginRight: "15px"}}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth sx={{ borderRadius: "5px", color: "black", marginBottom: "10px" }}>
                                                <InputLabel id="demo-simple-select-label">Locations</InputLabel>
                                                <Select
                                                    sx={{ borderRadius: "5px", color: "black", width: "180px"}}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={locationQuantity}
                                                    label="Warehouse"
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                          return <em>
                                                            {`Regional ( ${totalQuantity} )`}
                                                          </em>;
                                                      }}
                                                    placeholder="Select Warehouse"
                                                    onChange={handleWareHouseChange}
                                                >
                                                    <MenuItem selected value="" style={{cursor: "default"}} disabled={stock[parseInt(locationQuantity, 10)]?.qty === 0}>
                                                        {stock[parseInt(locationQuantity, 10)]?.locationObj.name} {" ( "} {stock[parseInt(locationQuantity, 10)]?.qty} {" )"}
                                                    </MenuItem>
                                                    {stock.map((oneStock: Stock, index: number) => (
                                                        (oneStock.locationObj.guestCutomerId !== stock[parseInt(locationQuantity, 10)]?.locationObj.guestCutomerId) &&
                                                        <MenuItem key={index} disabled={oneStock.qty === 0} value={index.toString()} style={{cursor: "default"}}>
                                                            {oneStock.locationObj.name} ( {oneStock.qty ?? 0} )
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </li>
                                    <li className="p_relative d_block float_left mr_10" style={{ width: "100px", marginRight: "15px" }}>
                                        <FormControl fullWidth sx={{ borderRadius: "5px", color: "black", marginBottom: "5px", width: "100%" }}>
                                            {/* <InputLabel id="demo-simple-select-label">Quantity</InputLabel> */}
                                            <TextField
                                            id="demo-simple-select"
                                            label="Quantity"
                                            type="number"
                                            value={quantity}
                                            onChange={handleChange}
                                            InputProps={{ sx: { borderRadius: "5px", color: "black" } }}
                                            InputLabelProps={{ shrink: true }} // Optional: shrink label on focus
                                            />
                                        </FormControl>
                                    </li>
                                    <li className="p_relative d_block float_left mr_10" style={{ marginLeft: "0px"}}>
                                        <div style={{display: "flex", flexDirection: "column", width: "100%", height: "55px"}}>
                                            <button 
                                                disabled={(buttonState(item) === "ADDED TO CART") /**((buttonState(item) === "ADDED TO CART") || (buttonState(item) === "OUT OF STOCK")) */}
                                                type="button" 
                                                className="theme-btn theme-btn-eight" 
                                                onClick={handleAddServices}
                                                style= {(buttonState(item) === "ADDED TO CART")? {height: "55px", backgroundColor: "darkgray"} : {height: "55px", backgroundColor: "#E7131A"}}
                                            >
                                                {buttonState(item)}
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                  
                        </Grid>
                    </Grid>
                </div>
            </section>
            {error && <Alert setError={setError} severity="error" text={errorMessage}/>}
        {optionalServices?.length!==0 && <OptionalServicesModal product={newCartItem} updateCart={updateCart} calculateTotal={calculateTotal} open={open} setOpen={setOpen} fields={newCartItem?.services && newCartItem?.services.length!==0?newCartItem.services:optionalServices} setOptions={setOptions} quantity={quantity} isCart={false} showConfirmationModal={showConfirmationModal} setShowConfirmationModal={setShowConfirmationModal}/>}
    </Grid>
  )}

export default TireDetailsPage;